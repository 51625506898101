import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/Bookshelf.css';

const AdventureTile = ({ adventure, onSelect, isSelected, onDelete }) => {
  const navigate = useNavigate();
  const [imagePath, setImagePath] = useState(null);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchAdventureImage = async () => {
      try {
        const response = await fetch(`/api/getAdventurePage?adventureID=${adventure.ID}&pageNumber=${adventure.CurrentPage}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch adventure page');
        }
        const data = await response.json();
        setImagePath(data.imagePath);
      } catch (error) {
        console.error('Error fetching adventure image:', error);
      }
    };

    fetchAdventureImage();
  }, [adventure.ID, adventure.CurrentPage, token]);

  const handleClick = () => {
    if (isSelected) {
      onSelect(null);
    } else {
      onSelect(adventure.ID);
    }
  };

  const handleNavigate = (e) => {
    e.stopPropagation();
    navigate(`/adventure/${adventure.ID}`, { state: { pageNumber: adventure.CurrentPage } });
  };

  return (
    <div className={`adventure-tile ${isSelected ? 'selected' : ''}`} onClick={handleClick}>
      <div className="adventure-cover" onClick={handleNavigate}>
        {imagePath && (
          <img src={`/images/${imagePath}`} alt={`Adventure ${adventure.ID}`} />
        )}
      </div>
      <div className="adventure-info">
        <h3>{adventure.Category} Adventure</h3>
        <p>Page {adventure.CurrentPage}</p>
      </div>
      {isSelected && (
        <button 
          className="delete-button" 
          onClick={(e) => { 
            e.stopPropagation(); 
            onDelete(adventure.ID); 
          }}
        >
          Delete
        </button>
      )}
    </div>
  );
};

const GenerateTile = ({ onGenerate, isGenerating, title, emoji }) => (
  <div className="generate-tile" onClick={isGenerating ? null : onGenerate}>
    {isGenerating ? (
      <div className="progress-bar">
        <div className="progress"></div>
      </div>
    ) : (
      <div className="generate-cover">
        <span>{emoji}</span>
      </div>
    )}
    <h3>{isGenerating ? "Generating..." : title}</h3>
  </div>
);

const Bookshelf = () => {
  const { category } = useParams();
  const [adventures, setAdventures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token, checkInvalidSession } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedAdventureId, setSelectedAdventureId] = useState(null);
  const [isGeneratingAdventure, setIsGeneratingAdventure] = useState(false);  // Add this line

  const handleDeleteSelectedAdventure = () => {
    if (selectedAdventureId) {
      handleDeleteAdventure(selectedAdventureId);
    }
  };

  useEffect(() => {
    const fetchAdventures = async () => {
      setLoading(true);
      try {
        const adventuresResponse = await fetch(`/api/getUserAdventuresByCategory?category=${category}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (await checkInvalidSession(adventuresResponse)) {
          return; // Stop execution if session is invalid
        }

        if (!adventuresResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const adventuresData = await adventuresResponse.json();
        
        if (Array.isArray(adventuresData) && adventuresData.length > 0) {
          adventuresData.sort((a, b) => a.ID - b.ID);
          setAdventures(adventuresData);
        } else {
          // Handle case when there are no adventures
          setAdventures([]);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load content. Please try again.');
        setLoading(false);
      }
    };

    fetchAdventures();
  }, [category, token, checkInvalidSession]);

  const handleInitiateAdventure = async () => {
    setIsGeneratingAdventure(true);
    try {
      const response = await fetch('/api/initiateAdventure', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          category: category,
          maxPages: 200,
        }),
      });

      if (await checkInvalidSession(response)) {
        return; // Stop execution if session is invalid
      }

      if (!response.ok) {
        throw new Error('Failed to initiate adventure');
      }

      const data = await response.json();
      navigate(`/adventure/${data.adventureID}`);
    } catch (error) {
      console.error('Error initiating adventure:', error);
      setError('Failed to initiate adventure. Please try again.');
    } finally {
      setIsGeneratingAdventure(false);
    }
  };

  const handleSelectAdventure = (adventureId) => {
    setSelectedAdventureId(prevId => prevId === adventureId ? null : adventureId);
  };

  const handleDeleteAdventure = async (adventureId) => {
    try {
      const response = await fetch(`/api/deleteAdventure?adventureID=${adventureId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete adventure');
      }

      setAdventures(prevAdventures => prevAdventures.filter(adventure => adventure.ID !== adventureId));
      setSelectedAdventureId(null);
    } catch (error) {
      console.error('Error deleting adventure:', error);
      setError('Failed to delete adventure. Please try again.');
    }
  };

  if (loading) {
    return <div className="bookshelf-page"><h2>Loading...</h2></div>;
  }

  if (error) {
    return <div className="bookshelf-page"><h2>Error: {error}</h2></div>;
  }

  return (
    <div className="bookshelf-page">
      <h2>{category} Adventures</h2>

      <div className="content-section">
        <div className="section-header">
          <h3>Adventures</h3>
          {selectedAdventureId && (
            <button className="delete-selected-button" onClick={handleDeleteSelectedAdventure}>
              Delete Selected Adventure
            </button>
          )}
        </div>
        <div className="content-grid">
          <GenerateTile 
            onGenerate={handleInitiateAdventure} 
            isGenerating={isGeneratingAdventure} 
            title="Start New Adventure" 
            emoji="🗺️" 
          />
          {adventures.map(adventure => (
            <AdventureTile 
              key={adventure.ID} 
              adventure={adventure} 
              onSelect={handleSelectAdventure}
              isSelected={selectedAdventureId === adventure.ID}
              onDelete={handleDeleteAdventure}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bookshelf;