import React from 'react';
import '../styles/InstallApp.css';

function InstallApp() {
  return (
    <div className="install-app-container">
      <h2>Install Kids Reading AI on your iPhone or iPad</h2>
      <div className="install-steps">
        <div className="step">
          <h3>Step 1</h3>
          <p>Tap the Share button in Safari.</p>
          <img src="/images/install_app_1.jpg" alt="Share button" className="step-image" />
          <p>or</p>
          <img src="/images/install_app_1.5.jpg" alt="Share button" className="step-image" />
        </div>
        <div className="step">
          <h3>Step 2</h3>
          <p>Scroll down and tap "Add to Home Screen".</p>
          <img src="/images/install_app_2.jpg" alt="Add to Home Screen" className="step-image" />
        </div>
        <div className="step">
          <h3>Step 3</h3>
          <p>Tap "Add" in the top-right corner.</p>
          <img src="/images/install_app_3.jpg" alt="Tap Add" className="step-image" />
        </div>
      </div>
      <p className="install-note">
        Once installed, you can launch Kids Reading AI directly from your home screen!
      </p>
    </div>
  );
}

export default InstallApp;