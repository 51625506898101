import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const useTokenCheck = () => {
  const { token, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) return;

    const checkToken = async () => {
      try {
        const response = await fetch('/api/checkToken', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            logout();
            navigate('/login');
          }
          return;
        }

        const data = await response.json();
        const remainingTime = data.remainingTime;

        if (remainingTime < 36000) { // Less than 10 hoursremaining
          logout();
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking token:', error);
      }
    };

    const intervalId = setInterval(checkToken, 3600000); // Check every hour

    return () => clearInterval(intervalId);
  }, [token, logout, navigate]);
};

export default useTokenCheck;