import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/BookReader.css';

const BookReader = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [book, setBook] = useState(null);
  const { bookId } = useParams();
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);

  useEffect(() => {
    document.body.classList.add('hide-top-banner');

    const fetchBook = async () => {
      try {
        const response = await fetch('/api/getUserBooks', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch books');
        }
        const books = await response.json();
        console.log(bookId)
        const selectedBook = books.find(b => b.id === parseInt(bookId));
        if (selectedBook) {
          setBook(selectedBook);
        } else {
          throw new Error(`Book not found: ${bookId}`);
        }
      } catch (error) {
        console.error('Error fetching book:', error);
      }
    };

    fetchBook();

    return () => {
      document.body.classList.remove('hide-top-banner');
    };
  }, [bookId, token]);

  const handlePageChange = (newPage) => {
    if (book && newPage >= 1 && newPage <= book.pages.length) {
      setCurrentPage(newPage);
    }
  };

  const handleClick = (e) => {
    const halfWidth = window.innerWidth / 2;
    if (e.clientX > halfWidth && currentPage < book.pages.length) {
      handlePageChange(currentPage + 1);
    } else if (e.clientX <= halfWidth && currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleSliderChange = (e) => {
    handlePageChange(parseInt(e.target.value, 10));
  };

  if (!book) {
    return <div className="book-reader"><h2>Loading...</h2></div>;
  }

  const currentPageData = book.pages[currentPage - 1];

  return (
    <div className="book-reader">
      <div className="book-content" onClick={handleClick}>
        {currentPageData.image_id && (
          <img
            src={`/images/${currentPageData.image_id}`}
            alt={`Page ${currentPage}`}
            className="book-image"
            draggable="false"
          />
        )}
        <div className="book-text-container">
          <p className="book-text">{currentPageData.text}</p>
        </div>
      </div>
      <button className="close-button" onClick={() => navigate(-1)}>
        &#10005;
      </button>
      <div className="progress-bar-container">
        <input 
          type="range" 
          min="1" 
          max={book.pages.length} 
          value={currentPage} 
          onChange={handleSliderChange}
          className="progress-slider"
        />
        <div className="page-indicator">{currentPage} / {book.pages.length}</div>
      </div>
    </div>
  );
};

export default BookReader;