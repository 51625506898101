import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/Header.css'; // Make sure to create this CSS file

function Header() {
  const [logoUrl, setLogoUrl] = useState('');
  const { token, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [language, setLanguage] = useState('en');
  const [gender, setGender] = useState('female');
  const [isIpad, setIsIpad] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    fetch('/api/logo')
      .then(response => response.json())
      .then(data => {
        if (data.logo) {
          setLogoUrl(data.logo);
        }
      })
      .catch(error => console.error('Error loading logo:', error));

    if (token) {
      fetchUserSettings();
    }
  }, [token]);

  const fetchUserSettings = async () => {
    try {
      const response = await fetch('/api/userSettings', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const settings = await response.json();
        setLanguage(settings.language);
        setGender(settings.narratorGender);
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const updateUserSettings = async (settings) => {
    try {
      const response = await fetch('/api/userSettings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          language: settings.language,
          narratorGender: settings.narratorGender
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update user settings');
      }
    } catch (error) {
      console.error('Error updating user settings:', error);
    }
  };

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    await updateUserSettings({ language: newLanguage, narratorGender: gender });
  };

  const handleGenderChange = async (e) => {
    const newGender = e.target.checked ? 'male' : 'female';
    setGender(newGender);
    await updateUserSettings({ language, narratorGender: newGender });
  };

  useEffect(() => {
    const checkIfIpad = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isIpadOS = /ipad/.test(userAgent) || (/macintosh/.test(userAgent) && 'ontouchend' in document);
      setIsIpad(isIpadOS);
    };

    const checkIfStandalone = () => {
      setIsStandalone(window.navigator.standalone === true);
    };

    checkIfIpad();
    checkIfStandalone();
    window.addEventListener('resize', checkIfIpad);

    return () => {
      window.removeEventListener('resize', checkIfIpad);
    };
  }, []);

  const handleInstallApp = () => {
    navigate('/install-app');
  };

  return (
    <header className="header">
      <div className="header-left">
        <Link to="/" className="header-title-link">
          <img src={logoUrl} alt="" className="header-logo" />
          <h1>Kids Reading AI</h1>
        </Link>
      </div>
      <nav className="header-nav">
        <ul>
          {token && (
            <>
              <li className="dropdown">
                <select value={language} onChange={handleLanguageChange}>
                  <option value="en">English</option>
                  <option value="es">Español</option>
                  <option value="fr">Français</option>
                  <option value="zh">中文</option>
                </select>
              </li>
              <li className="gender-slider">
                <span>F</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={gender === 'male'}
                    onChange={handleGenderChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span>M</span>
              </li>
            </>
          )}
          <li className="nav-item"><Link to="/">Home</Link></li>
          <li className="nav-item"><Link to="/subscribe">Subscribe</Link></li>
          {token ? (
            <li className="nav-item"><Link to="/settings">Settings</Link></li>
          ) : (
            <li className="nav-item"><Link to="/login">Login</Link></li>
          )}
          {isIpad && !isStandalone && (
            <li className="nav-item ipad-only-button">
              <button onClick={handleInstallApp}>
                Install App
              </button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Header;