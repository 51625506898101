import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// // Disable double-tap to zoom
// let lastTouchEnd = 0;
// document.addEventListener('touchend', function(event) {
//   const now = (new Date()).getTime();
//   if (now - lastTouchEnd <= 300) {
//     event.preventDefault();
//   }
//   lastTouchEnd = now;
// }, false);

// // Disable pinch-to-zoom
// document.addEventListener('gesturestart', function(event) {
//   event.preventDefault();
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();