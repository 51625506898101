import React, { useEffect } from 'react';
import '../styles/Subscribe.css';

function Subscribe() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="subscribe-container">
      <h2>Subscribe to Kids Reading AI Adventures</h2>
      <p>Choose a subscription plan to access our AI-generated adventure books and embark on endless adventures.</p>
      <stripe-buy-button
        buy-button-id="buy_btn_1Q4FkEIh9NluFhOm4W9GZXAP"
        publishable-key="pk_test_51HUciPIh9NluFhOmMa23SxxWQCKAUToe5NIJ3jGOQZLlUxMMNjVOYDWFoRXsE9j95joUYaTUfgnBbWeP1z31SgpV00rPzjOqZC"
      >
      </stripe-buy-button>
      <div className="stars">
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
      </div>
    </div>
  );
}

export default Subscribe;
