import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';
import { AuthContext } from '../context/AuthContext';
import { ERRORS } from '../constants/errors';

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();
  const passwordRef = useRef(null);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (action) => {
    if (!validateEmail(email)) {
      setMessage(ERRORS.INVALID_EMAIL);
      setMessageType('error');
      return;
    }

    if (action !== 'forgotPassword' && password.length < 8) {
      setMessage(ERRORS.PASSWORD_TOO_SHORT);
      setMessageType('error');
      return;
    }

    try {
      const response = await fetch(`/api/${action}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        if (action === 'login') {
          setMessage('Login successful!');
          setMessageType('success');
          login(data.token);
          setTimeout(() => navigate('/'), 1500);
        } else if (action === 'register') {
          setMessage('Registration successful!');
          setMessageType('success');
        } else if (action === 'forgotPassword') {
          setMessage('Password reset instructions sent to your email.');
          setMessageType('success');
          setIsForgotPassword(false);
        }
      } else {
        if (action === 'forgotPassword' && response.status === 429) {
          setMessage(data.error || ERRORS.LAST_RESET_WITHIN_5_MIN);
        } else {
          setMessage(data.error || `${action === 'login' ? 'Login' : action === 'register' ? 'Registration' : 'Password reset'} failed`);
        }
        setMessageType('error');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      setMessageType('error');
    }
  };

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      if (field === 'email' && !isForgotPassword) {
        passwordRef.current.focus();
      } else {
        handleSubmit(isForgotPassword ? 'forgotPassword' : 'login');
      }
    }
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
    setMessage('');
    setMessageType('');
  };

  return (
    <div className="login-container">
      <h2>{isForgotPassword ? 'Reset Password' : 'Login / Register'}</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, 'email')}
            required
          />
        </div>
        {!isForgotPassword && (
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, 'password')}
              required
              minLength="8"
              ref={passwordRef}
            />
          </div>
        )}
        <div className="button-group">
          {isForgotPassword ? (
            <button type="button" onClick={() => handleSubmit('forgotPassword')}>
              Reset Password
            </button>
          ) : (
            <>
              <button type="button" onClick={() => handleSubmit('register')}>
                Register
              </button>
              <button type="button" onClick={() => handleSubmit('login')}>
                Login
              </button>
            </>
          )}
        </div>
      </form>
      <button className="forgot-password-btn" onClick={toggleForgotPassword}>
        {isForgotPassword ? 'Back to Login' : 'Forgot Password?'}
      </button>
      {message && <p className={`message ${messageType}`}>{message}</p>}
    </div>
  );
};

export default Login;