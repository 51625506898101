import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/Settings.css';

function Settings() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="settings-container">
      <h2>Settings</h2>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      {/* Add more settings options here in the future */}
    </div>
  );
}

export default Settings;