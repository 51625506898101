import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import useTokenCheck from './hooks/useTokenCheck';
import Header from './components/Header';
import Home from './components/Home';
import Buy from './components/Subscribe';
import Login from './components/Login';
import BookReader from './components/BookReader';
import Bookshelf from './components/Bookshelf';
import AdventureReader from './components/AdventureReader';
import Settings from './components/Settings';
import ResetPassword from './components/ResetPassword';
import InstallApp from './components/InstallApp';

function AppContent() {
  useTokenCheck();

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/subscribe" element={<Buy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reader/:bookId" element={<BookReader />} />
        <Route path="/bookshelf/:category" element={<Bookshelf />} />
        <Route path="/adventure/:adventureId" element={<AdventureReader />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/install-app" element={<InstallApp />} />
      </Routes>
      <div className="space-object planet"></div>
      <div className="space-object comet"></div>
      <div className="space-object crescent-moon"></div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;