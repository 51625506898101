import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const navigate = useNavigate();

  const login = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  };

  const logout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setToken(null);
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const checkInvalidSession = async (response) => {
    if (response.status === 401) {
      const data = await response.json();
      if (data.error === 'invalid_session') {
        logout();
        navigate('/login');
        return true;
      }
    }
    return false;
  };

  const refreshToken = async () => {
    try {
      const response = await fetch('/api/refreshToken', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        login(data.token);
      } else {
        logout();
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  };

  useEffect(() => {
    // You can add token validation logic here if needed
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, login, logout, checkInvalidSession }}>
      {children}
    </AuthContext.Provider>
  );
};