import React, { useState, useEffect, useContext } from 'react';
import '../styles/Home.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function Home() {
  const categories = [
    "Dinosaurs", "Space", "Animals", "Superheroes", "Robots", "Princesses",
    "Science", "Construction Vehicles", "Sports", "Oceans and Seas",
    "Cooking and Food", "Weather", "Insects and Bugs", "Pirates",
    "Fantasy Creatures", "World Cultures and Traditions", "Mysteries and Detective Stories",
    "Inventions and Discoveries", "Castles and Knights", "Jungle", "Time Travel",
    "Computers", "Holidays", "Farming", "Architecture"
  ];

  const [categoryImages, setCategoryImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});

  const [highlightedWordIndex, setHighlightedWordIndex] = useState(-1);
  const titleWords = "kidsreading.ai Reads Stories Aloud to Your Kids".split(' ');

  const navigate = useNavigate();
  const { token } = useContext(AuthContext);

  const [isIOS, setIsIOS] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    categories.forEach(category => {
      setLoadingImages(prev => ({ ...prev, [category]: true }));
      const encodedCategory = encodeURIComponent(category.replace(/ /g, '_'));
      fetch(`/api/images/${encodedCategory}`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data.images && data.images.length > 0) {
            const randomIndex = Math.floor(Math.random() * data.images.length);
            const imageUrl = data.images[randomIndex];
            return fetch(imageUrl).then(imgResponse => {
              const contentType = imgResponse.headers.get('content-type');
              if (!contentType || !contentType.startsWith('image/')) {
                throw new Error(`Invalid content type: ${contentType}`);
              }
              return imageUrl;
            });
          } else {
            throw new Error(`No images found for ${category}`);
          }
        })
        .then(imageUrl => {
          setCategoryImages(prev => ({
            ...prev,
            [category]: imageUrl
          }));
        })
        .catch(error => {
          console.error(`Error loading images for ${category}:`, error);
          setCategoryImages(prev => ({ ...prev, [category]: null }));
        })
        .finally(() => {
          setLoadingImages(prev => ({ ...prev, [category]: false }));
        });
    });

    const animateTitle = () => {
      setHighlightedWordIndex((prevIndex) => {
        if (prevIndex === titleWords.length - 1) {
          setTimeout(() => {
            setHighlightedWordIndex(-1);
          }, 500);
          return -1;
        }
        return prevIndex + 1;
      });
    };

    const intervalId = setInterval(animateTitle, 500);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Check if the device is iOS and if the app is in standalone mode
    const checkIOSAndStandalone = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const iOS = /iphone|ipad|ipod/.test(userAgent);
      setIsIOS(iOS);
      setIsStandalone(window.navigator.standalone);
    };

    checkIOSAndStandalone();
  }, []);

  const handleTileClick = (category) => {
    console.log(`Clicked on ${category}`);
    if (token) {
      navigate(`/bookshelf/${encodeURIComponent(category)}`);
    } else {
      navigate('/login');
    }
  };

  const handleInstallApp = () => {
    navigate('/install-app');
  };

  return (
    <div className="home">
      {!token && (
        <>
          <h2>
            {titleWords.map((word, index) => (
              <span
                key={index}
                style={{
                  color: index === highlightedWordIndex ? '#1E90FF' : 'white',
                  fontWeight: index === highlightedWordIndex ? 'bold' : 'normal',
                  transition: 'color 0.3s, font-weight 0.3s',
                }}
              >
                {word}{' '}
              </span>
            ))}
          </h2>
          <p>Give your child an advantage on early literacy!</p>
        </>
      )}
      {isIOS && !isStandalone && (
        <button className="install-app-button" onClick={handleInstallApp}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
          </svg>
          Install App
        </button>
      )}
      <div className="image-grid">
        {categories.map((category, index) => (
          <div
            key={index}
            className="image-tile"
            style={{ position: 'relative' }}
            onClick={() => handleTileClick(category)}
          >
            <div
              className="image-container"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#e0e0e0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                cursor: 'pointer',
              }}
            >
              {loadingImages[category] ? (
                <p>Loading...</p>
              ) : categoryImages[category] ? (
                <img
                  src={categoryImages[category]}
                  alt={category}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  onError={() => {
                    console.error(`Failed to load image for ${category}`);
                    setCategoryImages(prev => ({ ...prev, [category]: null }));
                  }}
                />
              ) : (
                <p>No image available</p>
              )}
            </div>
            <div className="subtitle">{category}</div>
            <button
              className="play-btn"
              aria-label="Play"
              onClick={(e) => {
                e.stopPropagation();
                handleTileClick(category);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;