import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/AdventureReader.css';

const AdventureReader = () => {
  const location = useLocation();
  const initialPage = location.state?.pageNumber || 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [highestPageReached, setHighestPageReached] = useState(initialPage);
  const [adventure, setAdventure] = useState(null);
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const { adventureId } = useParams();
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [imageLoadAttempts, setImageLoadAttempts] = useState(0);
  const imageRef = useRef(null);
  const [voiceStatus, setVoiceStatus] = useState('pending');
  const [audioSrc, setAudioSrc] = useState(null);
  const [voicePollingInterval, setVoicePollingInterval] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(-1);
  const wordRefs = useRef([]);
  const [autoReadEnabled, setAutoReadEnabled] = useState(true);
  const [uiVisible, setUiVisible] = useState(true);
  const [choiceAudioSrcs, setChoiceAudioSrcs] = useState([]);
  const [choiceVoiceStatuses, setChoiceVoiceStatuses] = useState([]);
  const [currentChoiceIndex, setCurrentChoiceIndex] = useState(-1);
  const choiceWordRefs = useRef([]);
  const [isPlayingChoices, setIsPlayingChoices] = useState(false);
  const [choiceAudioRefs, setChoiceAudioRefs] = useState([]);
  const [currentChoiceWordIndex, setCurrentChoiceWordIndex] = useState(-1);
  const [choicesPlayed, setChoicesPlayed] = useState(false);
  const [playingChoiceIndex, setPlayingChoiceIndex] = useState(-1);
  const [autoPlayingChoiceIndex, setAutoPlayingChoiceIndex] = useState(-1);
  const [readingChoiceIndex, setReadingChoiceIndex] = useState(-1);
  const [shouldAutoPlayChoices, setShouldAutoPlayChoices] = useState(false);

  const [wordTimestamps, setWordTimestamps] = useState([]);
  const [choiceWordTimestamps, setChoiceWordTimestamps] = useState([]);
  const [currentWord, setCurrentWord] = useState('');
  const [currentChoiceWord, setCurrentChoiceWord] = useState('');
  const [choiceWordIndices, setChoiceWordIndices] = useState([]);

  const [showAutoplayPrompt, setShowAutoplayPrompt] = useState(false);
  const [audioError, setAudioError] = useState(false);

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const resetAudioState = useCallback(() => {
    setIsPlaying(false);
    setCurrentWordIndex(-1);
    setIsPlayingChoices(false);
    setCurrentChoiceIndex(-1);
    setCurrentChoiceWordIndex(-1);
    setChoicesPlayed(false);
    setAutoPlayingChoiceIndex(-1);
    setPlayingChoiceIndex(-1);
    setReadingChoiceIndex(-1);
    setShouldAutoPlayChoices(false);

    // Stop all choice audio
    choiceAudioRefs.forEach(audioRef => {
      if (audioRef) {
        audioRef.pause();
        audioRef.currentTime = 0;
      }
    });

    // Stop main audio
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [choiceAudioRefs]);

  useEffect(() => {
    document.body.classList.add('hide-top-banner');
    fetchAdventurePage();
    resetAudioState();

    return () => {
      document.body.classList.remove('hide-top-banner');
      if (voicePollingInterval) {
        clearInterval(voicePollingInterval);
      }
      resetAudioState();
    };
  }, [adventureId, token, currentPage, resetAudioState]);

  useEffect(() => {
    if (adventure?.voiceId) {
      setVoiceStatus('pending');
      startVoicePolling(adventure.voiceId);
    }
  }, [adventure]);

  useEffect(() => {
    if (adventure?.choiceVoiceIds) {
      setChoiceVoiceStatuses(adventure.choiceVoiceIds.map(() => 'pending'));
      adventure.choiceVoiceIds.forEach((voiceId, index) => {
        startChoiceVoicePolling(voiceId, index);
      });
    }
  }, [adventure]);

  const startVoicePolling = (voiceID, onVoiceReady) => {
    if (voicePollingInterval) {
      clearInterval(voicePollingInterval);
    }

    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`/api/getVoiceStatus?voiceId=${voiceID}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch voice status');
        }
        const data = await response.json();
        setVoiceStatus(data.status);
        if (data.status === 'completed') {
          clearInterval(intervalId);
          await fetchVoiceData(voiceID);
          if (typeof onVoiceReady === 'function') {
            onVoiceReady();
          }
        }
      } catch (error) {
        console.error('Error fetching voice status:', error);
        clearInterval(intervalId);
      }
    }, 500);

    setVoicePollingInterval(intervalId);
  };

  const startChoiceVoicePolling = (voiceId, index) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`/api/getVoiceStatus?voiceId=${voiceId}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (!response.ok) throw new Error('Failed to fetch choice voice status');
        const data = await response.json();
        setChoiceVoiceStatuses(prevStatuses => {
          const newStatuses = [...prevStatuses];
          newStatuses[index] = data.status;
          return newStatuses;
        });
        if (data.status === 'completed') {
          clearInterval(intervalId);
          fetchChoiceVoiceData(voiceId, index);
        }
      } catch (error) {
        console.error('Error fetching choice voice status:', error);
        clearInterval(intervalId);
      }
    }, 500);

    setVoicePollingInterval(intervalId);
  };

  const fetchAdventurePage = async () => {
    try {
      const response = await fetch(`/api/getAdventurePage?adventureID=${adventureId}&pageNumber=${currentPage}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch adventure page');
      }
      const data = await response.json();
      setAdventure(data);
      setLoading(false);
      setVoiceStatus('pending');
      setAudioSrc(null);
      setWordTimestamps([]);
      if (data.voiceId) {
        startVoicePolling(data.voiceId);
      }
    } catch (error) {
      console.error('Error fetching adventure page:', error);
      setLoading(false);
    }
  };

  const fetchVoiceData = async (voiceID) => {
    try {
      const response = await fetch(`/api/getVoiceData?voiceId=${voiceID}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch voice data');
      }
      const data = await response.json();
      setAudioSrc(`/images/${data.audioFilePath}`);
      setVoiceStatus('completed');

      // Set word timestamps directly
      setWordTimestamps(data.alignment);
    } catch (error) {
      console.error('Error fetching voice data:', error);
    }
  };

  const fetchChoiceVoiceData = async (voiceId, index) => {
    try {
      const response = await fetch(`/api/getVoiceData?voiceId=${voiceId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Failed to fetch choice voice data');
      const data = await response.json();
      setChoiceAudioSrcs(prevSrcs => {
        const newSrcs = [...prevSrcs];
        newSrcs[index] = `/images/${data.audioFilePath}`;
        return newSrcs;
      });

      // Update to use the new alignment array format
      setChoiceWordTimestamps(prevTimestamps => {
        const newTimestamps = [...prevTimestamps];
        newTimestamps[index] = data.alignment;
        return newTimestamps;
      });
    } catch (error) {
      console.error('Error fetching choice voice data:', error);
    }
  };

  useEffect(() => {
    if (audioSrc && voiceStatus === 'completed' && audioRef.current && autoReadEnabled) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
      }).catch(error => {
        console.error('Error auto-playing audio:', error);
        if (isIOS() && !audioError) {  // Check if there's no audio error
          setShowAutoplayPrompt(true);
        }
      });
    }
  }, [audioSrc, voiceStatus, autoReadEnabled, audioError]);  // Add audioError to dependencies

  useEffect(() => {
    if (!isPlaying && autoReadEnabled && !isPlayingChoices && adventure?.choices?.length > 0 && !choicesPlayed && shouldAutoPlayChoices) {
      playChoicesSequentially();
    }
  }, [isPlaying, autoReadEnabled, isPlayingChoices, adventure, choicesPlayed, shouldAutoPlayChoices]);

  const playChoicesSequentially = async () => {
    setIsPlayingChoices(true);
    for (let i = 0; i < adventure.choices.length; i++) {
      if (choiceAudioSrcs[i] && choiceVoiceStatuses[i] === 'completed') {
        setCurrentChoiceIndex(i);
        setAutoPlayingChoiceIndex(i);
        setReadingChoiceIndex(i);
        await new Promise((resolve) => {
          const audio = new Audio(choiceAudioSrcs[i]);
          choiceAudioRefs[i] = audio;
          audio.addEventListener('ended', () => {
            setAutoPlayingChoiceIndex(-1);
            setReadingChoiceIndex(-1);
            resolve();
          });
          audio.addEventListener('timeupdate', () => highlightChoiceWords(i, audio.currentTime));
          audio.play().catch(error => {
            console.error('Error playing choice audio:', error);
            setAutoPlayingChoiceIndex(-1);
            setReadingChoiceIndex(-1);
            resolve();
          });
        });
      }
    }
    setCurrentChoiceIndex(-1);
    setIsPlayingChoices(false);
    setCurrentChoiceWordIndex(-1);
    setChoicesPlayed(true);
    setAutoPlayingChoiceIndex(-1);
    setReadingChoiceIndex(-1);
    setShouldAutoPlayChoices(false);
  };

  const highlightChoiceWords = useCallback((choiceIndex, currentTime) => {
    if (!choiceWordTimestamps[choiceIndex] || !choiceAudioRefs[choiceIndex]) return;

    // Add this check to prevent highlighting when choice audio is not playing
    if (choiceAudioRefs[choiceIndex].paused) {
      setChoiceWordIndices(prev => {
        const newIndices = [...prev];
        newIndices[choiceIndex] = -1;
        return newIndices;
      });
      return;
    }

    const timestamps = choiceWordTimestamps[choiceIndex];

    for (let i = 0; i < timestamps.length; i++) {
      const startTime = timestamps[i];
      const endTime = i < timestamps.length - 1 ? timestamps[i + 1] : choiceAudioRefs[choiceIndex].duration;

      if (currentTime >= startTime && currentTime < endTime) {
        setChoiceWordIndices(prev => {
          const newIndices = [...prev];
          newIndices[choiceIndex] = i;
          return newIndices;
        });
        return;
      }
    }

    // If no word is currently being spoken, reset the index
    setChoiceWordIndices(prev => {
      const newIndices = [...prev];
      newIndices[choiceIndex] = -1;
      return newIndices;
    });
  }, [choiceWordTimestamps, choiceAudioRefs]);

  const handleChoiceClick = async (choice) => {
    if (generating) return;
    setGenerating(true);
    resetAudioState();

    try {
      const response = await fetch('/api/generateNextPage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          adventureID: parseInt(adventureId, 10),
          pageNumber: currentPage,
          choice: choice,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to generate next page');
      }
      const data = await response.json();
      if (data.page) {
        setAdventure(data.page);
        setCurrentPage(data.page.pageNumber);
        setHighestPageReached(prevHighest => Math.max(prevHighest, data.page.pageNumber));
        navigate(`/adventure/${adventureId}`, { state: { pageNumber: data.page.pageNumber }, replace: true });

        // Start voice polling with a callback to play audio when ready
        if (data.page.voiceId) {
          startVoicePolling(data.page.voiceId, () => {
            if (audioRef.current && autoReadEnabled && audioSrc) {
              audioRef.current.play().then(() => {
                setIsPlaying(true);
              }).catch(error => {
                console.error('Error auto-playing audio:', error);
              });
            }
          });
        }
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.error('Error generating next page:', error);
    } finally {
      setGenerating(false);
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      resetAudioState();
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < highestPageReached) {
      resetAudioState();
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play().then(() => {
          setIsPlaying(true);
        }).catch(error => {
          console.error('Error playing audio:', error);
        });
      }
    }
  };

  const toggleAutoRead = () => {
    setAutoReadEnabled(!autoReadEnabled);
    if (!autoReadEnabled && audioSrc && voiceStatus === 'completed' && audioRef.current) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
      }).catch(error => {
        console.error('Error playing audio:', error);
      });
    } else if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const highlightWords = useCallback(() => {
    if (!audioRef.current || !wordTimestamps || wordTimestamps.length === 0) return;

    const currentTime = audioRef.current.currentTime;
    
    for (let i = 0; i < wordTimestamps.length; i++) {
      const startTime = wordTimestamps[i];
      const endTime = i < wordTimestamps.length - 1 ? wordTimestamps[i + 1] : audioRef.current.duration;

      if (currentTime >= startTime && currentTime < endTime) {
        setCurrentWordIndex(i);
        return;
      }
    }

    setCurrentWordIndex(-1); // No word is currently being spoken
  }, [wordTimestamps]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', highlightWords);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', highlightWords);
      }
    };
  }, [highlightWords]);

  const renderHighlightedText = () => {
    if (!adventure || !adventure.text) return null;

    const words = adventure.text.split(/\s+/);

    return (
      <p className="adventure-text">
        {words.map((word, index) => (
          <span
            key={index}
            className={
              index === currentWordIndex
                ? 'highlighted-word'
                : index < currentWordIndex
                ? 'past-word'
                : ''
            }
          >
            {word + ' '}
          </span>
        ))}
      </p>
    );
  };

  const toggleUiVisibility = () => {
    setUiVisible(!uiVisible);
  };

  const playChoiceAudio = (index) => {
    if (choiceAudioSrcs[index] && choiceVoiceStatuses[index] === 'completed') {
      if (playingChoiceIndex === index || autoPlayingChoiceIndex === index) {
        choiceAudioRefs[index].pause();
        setPlayingChoiceIndex(-1);
        setAutoPlayingChoiceIndex(-1);
        setReadingChoiceIndex(-1);
      } else {
        if (playingChoiceIndex !== -1) {
          choiceAudioRefs[playingChoiceIndex].pause();
          choiceAudioRefs[playingChoiceIndex].currentTime = 0;
        }
        setCurrentChoiceIndex(index);
        setPlayingChoiceIndex(index);
        setReadingChoiceIndex(index);
        const audio = new Audio(choiceAudioSrcs[index]);
        choiceAudioRefs[index] = audio;
        audio.addEventListener('ended', () => {
          setCurrentChoiceIndex(-1);
          setPlayingChoiceIndex(-1);
          setReadingChoiceIndex(-1);
        });
        audio.addEventListener('timeupdate', () => highlightChoiceWords(index, audio.currentTime));
        audio.play();
      }
    }
  };

  const renderHighlightedChoiceText = (choice, index) => {
    const words = choice.split(/\s+/);

    return (
      <p>
        {words.map((word, wordIndex) => (
          <span
            key={wordIndex}
            className={
              wordIndex === choiceWordIndices[index]
                ? 'highlighted-choice-word'
                : wordIndex < choiceWordIndices[index]
                ? 'past-choice-word'
                : ''
            }
          >
            {word + ' '}
          </span>
        ))}
      </p>
    );
  };

  const handleAutoplayPromptClick = () => {
    if (audioRef.current) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
        setShowAutoplayPrompt(false);
      }).catch(error => {
        console.error('Error playing audio after user interaction:', error);
        setShowAutoplayPrompt(false);  // Exit the overlay on error
      });
    } else {
      // Exit the overlay if audioRef.current is null
      setShowAutoplayPrompt(false);
    }
  };

  const handleAudioError = () => {
    setAudioError(true);
  };

  if (loading) {
    return <div className="adventure-reader"><h2>Loading...</h2></div>;
  }

  if (!adventure) {
    return <div className="adventure-reader"><h2>Adventure not found</h2></div>;
  }

  return (
    <div className="adventure-reader">
      <div className="adventure-content">
        {adventure.imagePath && (
          <img
            ref={imageRef}
            src={`/images/${adventure.imagePath}`}
            alt={`Page ${adventure.pageNumber}`}
            className="adventure-image"
            onLoad={() => setImageLoadAttempts(0)}
            onError={() => setImageLoadAttempts(prevAttempts => prevAttempts + 1)}
          />
        )}
        <div className="navigation-overlay">
          {currentPage > 1 && (
            <button onClick={handlePrevPage} className="nav-button prev-button">
              &#10216;
            </button>
          )}
          {currentPage < highestPageReached && (
            <button onClick={handleNextPage} className="nav-button next-button">
              &#10217;
            </button>
          )}
        </div>
        <div className="adventure-choices" style={{ display: uiVisible ? 'grid' : 'none' }}>
          {adventure.choices.map((choice, index) => (
            <div key={index} className="choice-container">
              {index % 2 === 0 && (
                <button
                  className="play-choice-button left"
                  onClick={() => playChoiceAudio(index)}
                  disabled={!choiceAudioSrcs[index] || choiceVoiceStatuses[index] !== 'completed'}
                >
                  {playingChoiceIndex === index || autoPlayingChoiceIndex === index ? '⏸' : '▶'}
                </button>
              )}
              <button
                onClick={() => handleChoiceClick(choice)}
                disabled={generating}
                className={`adventure-choice-button ${readingChoiceIndex === index ? 'being-read' : ''}`}
              >
                {renderHighlightedChoiceText(choice, index)}
              </button>
              {index % 2 === 1 && (
                <button
                  className="play-choice-button right"
                  onClick={() => playChoiceAudio(index)}
                  disabled={!choiceAudioSrcs[index] || choiceVoiceStatuses[index] !== 'completed'}
                >
                  {playingChoiceIndex === index || autoPlayingChoiceIndex === index ? '⏸' : '▶'}
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="audio-controls">
          <button
            className="audio-play-button"
            onClick={toggleAudio}
            disabled={!audioSrc || voiceStatus !== 'completed'}
          >
            {isPlaying ? '⏸' : '▶'}
          </button>
          <button
            className="auto-read-toggle"
            onClick={toggleAutoRead}
            title={autoReadEnabled ? "Disable auto-read" : "Enable auto-read"}
          >
            {autoReadEnabled ? '🔊' : '🔇'}
          </button>
        </div>
        <div className="adventure-text-container" style={{ display: uiVisible ? 'block' : 'none' }}>
          {renderHighlightedText()}
        </div>
      </div>
      {audioSrc && (
        <audio
          ref={audioRef}
          src={audioSrc}
          onEnded={() => {
            setIsPlaying(false);
            if (autoReadEnabled && adventure?.choices?.length > 0) {
              setShouldAutoPlayChoices(true);
            }
          }}
          onError={handleAudioError}
        />
      )}
      <button className="close-button" onClick={handleClose}>
        &#10005;
      </button>
      <button className="toggle-ui-button" onClick={toggleUiVisibility}>
        {uiVisible ? '👁️' : '👁️‍🗨️'}
      </button>
      {showAutoplayPrompt && (
        <div className="autoplay-prompt-overlay" onClick={handleAutoplayPromptClick}>
          <p>Tap to play audio</p>
        </div>
      )}
    </div>
  );
};

export default AdventureReader;